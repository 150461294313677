import UIfx from 'uifx';
import correctAudio from '../sfx/correct.mp3';
import wrongAudio from '../sfx/wrong.mp3';
import clickAudio from '../sfx/click3.ogg';
import fartAudio from '../sfx/fart.mp3';
import submitAudio from '../sfx/submit.ogg';
import timeAudio from '../sfx/click1.ogg';
import React from 'react';

let volume = 1;
const correct = new UIfx(correctAudio);
const wrong = new UIfx(wrongAudio);
const click = new UIfx(clickAudio);
const time = new UIfx(timeAudio);
const fart = new UIfx(fartAudio);
const submit = new UIfx(submitAudio);

export const audManager = {volume,correct,wrong,click,fart,submit,time};
export const AudioContext = React.createContext();



