import './App.css';
import Home from './Home/Home';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Lobby from './Lobby/Lobby';
import Game from './Game/Game';
import React, {useState,useContext} from 'react';
import {SocketContext, socket} from './context/socket';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch2 from '@material-ui/core/Switch';
import { useMediaQuery } from "@material-ui/core";
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeMuteIcon from '@material-ui/icons/VolumeMute';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import {AudioContext} from './context/audio';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import LightBulbIcon from '@material-ui/icons/EmojiObjects';
import QuestionIcon from '@material-ui/icons/Help';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import screenshot from './img/Screenshot.png';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper:{
    display: 'flex',
    flexDirection:'column',
    height:"80%",
    margin:20
  },
  howToPlay:{
    flexGrow:1,
    overflowY:"scroll",
    marginInline:theme.spacing(3),
    marginBottom:theme.spacing(3)
  },
  crossButton:{
    margin:theme.spacing(1),
  }
}));

function App() {
  const classes = useStyles();
  const audManager = useContext(AudioContext);
  const [mute,setMute] = useState(false);
  const [openHelpModal, setOpenHelpModal] = React.useState(false);
  const [prefersDarkMode, setPrefersDarkMode] = useState(true);
  const theme = createMuiTheme({
    palette: {
      type: prefersDarkMode ? 'dark' : 'light',
    },
    typography:{
      fontFamily:
      [
        "Fredoka One",
        "cursive"
      ].join(','),
    }
  });
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleOpenHelpModal = () => {
    setOpenHelpModal(true);
  };

  const handleCloseHelpModal = () => {
    setOpenHelpModal(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline></CssBaseline>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openHelpModal}
        onClose={handleCloseHelpModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openHelpModal}>
          <Paper className={classes.paper}>
            <div style={{width:"100%",display:"flex",justifyContent:"flex-end"}}>
            <IconButton className={classes.crossButton} onClick={handleCloseHelpModal}>
              <CloseIcon />
            </IconButton>
            </div>
            <div className={classes.howToPlay}>
              <Box style={{marginTop:0}}  color="secondary.main" fontSize="h4.fontSize" m={1}>
              How To play
              </Box>
                    Mystery Sign is a online web multiplayer game.<br/> In Mystery Sign, you must find the answer to
                a problem to earn points.<br/>The player with the most points after 5 rounds wins.<br/>
                <p>
                <img width="100%" src={screenshot} alt="Screenshot"></img>
                The game consists of <Box component="span" color="secondary.main">5 rounds</Box> and a problem made up of two numbers and a mystery sign will be revealed to both players each round.
                You must then find the problem's hidden rule and guess the correct answer.<br/>
                </p>
                There are two phases, the <LightBulbIcon style={{ color: "yellow"}}/> Hint Phase and a <QuestionIcon  color="primary"/> guess phase. <br/>
                <p>During the hint phase two random players will be assigned as the hinters.<br/>
                The two hinters will give a number between -999 to 999 which will be used to form a hint.<br/>
                The hint will give the answer based on the problem's hidden rule.<br/></p>
                <p>For example, the problem is <Box component="span" color="secondary.main">5?10=</Box> and the hidden rule is the <Box component="span" color="info.main">sum of the squares of the two numbers.</Box><br/>
                If the hinters give the numbers 3 and 2, the hidden rule will be applied and the hint given will be <Box component="span" color="info.main">3?2=13</Box> as 3<sup>2</sup> + 2<sup>2</sup> = 13.
                After seeing the hint, you must find the hidden rule based on the hint and solve <Box component="span" color="info.main">5<sup>2</sup> + 10<sup>2</sup></Box> to get the answer <Box component="span" color="info.main">125.</Box><br/></p>
                You will be given <Box component="span" color="success.main">1 minute</Box> to answer in the guess phase and can only attempt to submit an answer once in each guess phase.<br/>
                The first player to figure out the pattern and submit a correct answer will earn <Box component="span" color="success.main">2 points.</Box><br/>
                The rest of the players that give the correct answer within the same guess phase will still receive <Box component="span" color="success.main">1 point.</Box><br/>
                If no one is able to guess within the <QuestionIcon  color="primary"/> guess phase, the game proceeds with a new <LightBulbIcon style={{ color: "yellow"}}/> hint phase.<br/>
                If a player submitted a wrong answer while there are less than 3 hints shown, the player will be penalised and will get <Box component="span" color="error.main">-1 points.</Box><br/>
                The round ends if a single person is able to answer correctly.<br/>
                <p>If no one is able to answer within three hints, the SKIP button will be available for players vote to skip the round.<br/>
                If all players want to skip, the round will end after the next guess phase.<br/>
                If there are at least one person who wants to skip, the round will end after 5 hints regardless of the number of people wanting to skip the round.<br/></p>
            </div>
          
          </Paper>
        </Fade>
      </Modal>

      <div style={{paddingTop:5,width:"100%",position:isSmallerScreen?"static":"absolute",display:"flex",flexDirection:"row",justifyContent:"flex-end"}}> 
      <Button
        size="large"
        style={{marginRight:10,textTransform:"none"}}
        color="default"
        startIcon={<HelpIcon />}
        onClick={handleOpenHelpModal}
      >
        How To Play
      </Button>
        <FormControlLabel
          control={<Switch2 color="default" checked={!prefersDarkMode} onChange={()=>{setPrefersDarkMode(!prefersDarkMode)}} name="Light or Dark Theme" />}
          label={prefersDarkMode?"Dark Mode":"Light Mode"}
        />
        <IconButton style={{marginRight:10}} onClick={()=>{console.log("changevol");audManager.volume=(!mute?0:1);setMute(!mute);}} aria-label="volume">
          {mute ?<VolumeMuteIcon /> :<VolumeUpIcon/>}
          
        </IconButton>
        
      </div>
      <SocketContext.Provider value={socket}>
          <Router>
              <Switch>
                  <Route path="/game">
                    <Game />
                  </Route>
                  <Route path="/lobby">
                    <Lobby />
                  </Route>
                  <Route path="/">
                    <Home />
                  </Route>
                </Switch>
            </Router>
        </SocketContext.Provider>
        
    </ThemeProvider>
    
   

  );
}

export default App;
