import logo from "../logo.svg";
import screenshot from "../img/Screenshot.png";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { useHistory } from "react-router-dom";
import { SocketContext } from "../context/socket";
import { AudioContext } from "../context/audio";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import LightBulbIcon from "@material-ui/icons/EmojiObjects";
import QuestionIcon from "@material-ui/icons/Help";
import React, { useState, useContext, useEffect, useRef } from "react";
import ScrollTop from "./ScrollTop";
import TwitterIcon from "@material-ui/icons/Twitter";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import MedianetADS from "../MediaNetAds";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingContainer: {
    padding: theme.spacing(2),
  },
  connectText: {
    marginRight: theme.spacing(2),
  },
}));

function Home() {
  const classes = useStyles();
  const howToPlayRef = useRef(null);
  const termsRef = useRef(null);
  const privacyRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);
  const twitterRef = useRef(null);
  const scrollHowToPlay = () =>
    howToPlayRef.current.scrollIntoView({ behavior: "smooth" });
  const scrollTerms = () =>
    termsRef.current.scrollIntoView({ behavior: "smooth" });
  const scrollPrivacy = () =>
    privacyRef.current.scrollIntoView({ behavior: "smooth" });
  const scrollAbout = () =>
    aboutRef.current.scrollIntoView({ behavior: "smooth" });
  const scrollContact = () =>
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  const scrollTwitter = () =>
    twitterRef.current.scrollIntoView({ behavior: "smooth" });
  const history = useHistory();
  const socket = useContext(SocketContext);
  const audManager = useContext(AudioContext);
  const [usernameError, setUsernameError] = useState(false);
  const [username, setUsername] = useState("");
  const [roomName, setRoomName] = useState("");
  const [isJoinRoom, setIsJoinRoom] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);

  useEffect(() => {
    // as soon as the component is mounted, do the following tasks:
    // subscribe to socket events
    socket.on("gameCode", (gameCode) => {
      history.push("/lobby", { gameCode: gameCode });
    });
    socket.on("connect", () => {
      setOpenLoading(false);
    });

    return () => {
      // before the component is destroyed
      // unbind all event handlers used in this component
      console.log("socket off gamecode");
      socket.off("gameCode");
      socket.off("connect");
    };
  }, [socket, history]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const validUsername = () => {
    if (username.length === 0) {
      setUsernameError(true);
      return false;
    }
    setUsernameError(false);
    return true;
  };

  const checkConnected = () => {
    if (!socket.connected) {
      setOpenLoading(true);
    }
  };

  return (
    <Container maxWidth="md">
      <Backdrop className={classes.backdrop} open={openLoading}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.loadingContainer}
        >
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            xs={12}
          >
            <Typography variant="h6" className={classes.connectText}>Connecting to server.</Typography>
            <CircularProgress color="inherit" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="p">
            There might be a delay as the server is hosted in a free tier plan.
            </Typography>
          </Grid>
        </Grid>
      </Backdrop>
      <ScrollTop />
      <Grid
        style={isSmallScreen ? { marginTop: 20 } : { minHeight: "100vh" }}
        direction="column"
        container
        justify="center"
        alignItems="center"
      >
        <Grid
          item
          direction="row"
          container
          justify="center"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={6}
            container
            direction="column"
            justify="center"
            alignItems={isSmallScreen ? "center" : "flex-end"}
          >
            <div
              id="back-to-top-anchor"
              style={{
                textAlign: "center",
                width: 250,
                marginRight: isSmallScreen ? 0 : 25,
              }}
            >
              <img src={logo} className="App-logo" alt="logo" />
              <Typography component="div">
                <Box fontSize="h4.fontSize" m={1}>
                  Mystery Sign
                </Box>
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs
            container
            direction="column"
            justify="center"
            alignItems={isSmallScreen ? "center" : "flex-start"}
          >
            <div style={{ width: 200, marginLeft: isSmallScreen ? 0 : 25 }}>
              <TextField
                error={usernameError}
                onChange={(event) => {
                  if (event.target.value.length < 33)
                    setUsername(event.target.value);
                }}
                value={username}
                label="Username"
                variant="outlined"
                size="small"
                style={{ width: 200 }}
                helperText={usernameError ? "Username not filled" : ""}
              />
              <Button
                onClick={() => {
                  audManager.click.play(audManager.volume);
                  if (validUsername()) {
                    checkConnected();
                    socket.emit("randomGame", username);
                  }
                }}
                size="medium"
                variant="contained"
                style={{ width: 200, marginTop: 20 }}
              >
                Random Room
              </Button>
              <Button
                onClick={() => {
                  audManager.click.play(audManager.volume);
                  if (validUsername()) {
                    socket.emit("newGame", username);
                  }
                }}
                size="medium"
                variant="contained"
                style={{ width: 200, marginTop: 20 }}
              >
                Create Room
              </Button>
              {isJoinRoom && (
                <TextField
                  onChange={(event) => {
                    setRoomName(event.target.value);
                  }}
                  value={roomName}
                  label="Room Code"
                  variant="outlined"
                  size="small"
                  style={{ width: 200, marginTop: 20 }}
                />
              )}
              <Button
                onClick={() => {
                  audManager.click.play(audManager.volume);
                  if (isJoinRoom && validUsername()) {
                    socket.emit("joinGame", username, roomName);
                  } else {
                    setIsJoinRoom(true);
                  }
                }}
                size="medium"
                variant="contained"
                style={{ width: 200, marginTop: 20 }}
              >
                Join Room
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid
          xs={12}
          style={{ paddingTop: 30 }}
          item
          direction="row"
          container
          alignItems="center"
          justify="center"
        >
          <Button onClick={scrollTerms} style={{ paddingInline: 20 }}>
            <Typography variant="overline">Terms Of Service</Typography>
          </Button>
          <Divider orientation="vertical" flexItem />
          <Button onClick={scrollPrivacy} style={{ paddingInline: 20 }}>
            <Typography variant="overline">Privacy</Typography>
          </Button>
          <Divider orientation="vertical" flexItem />
          <Button onClick={scrollAbout} style={{ paddingInline: 20 }}>
            <Typography variant="overline">About</Typography>
          </Button>
          <Divider orientation="vertical" flexItem />
          <Button onClick={scrollContact} style={{ paddingInline: 20 }}>
            <Typography variant="overline">Contact</Typography>
          </Button>
          <Divider orientation="vertical" flexItem />
          <Button onClick={scrollHowToPlay} style={{ paddingInline: 20 }}>
            <Typography variant="overline">How to Play</Typography>
          </Button>
          <Divider orientation="vertical" flexItem />
          <Button onClick={scrollTwitter} style={{ paddingInline: 20 }}>
            <Typography variant="overline">Anouncements</Typography>
          </Button>
        </Grid>
        <MedianetADS size="970x90" divId="151776618" />
      </Grid>
      <Grid
        xs={12}
        style={{ paddingTop: 30 }}
        item
        direction="column"
        container
        alignItems="center"
        justify="center"
      >
        <Typography component="div">
          <Box
            className="Home-header"
            ref={twitterRef}
            color="secondary.main"
            fontSize="h4.fontSize"
            m={1}
          >
            Announcements{" "}
            <Box component="span" color="#00acee">
              (<TwitterIcon fontSize="large" />
              Twitter)
            </Box>
          </Box>
          <div style={{ maxWidth: 600 }}>
            <TwitterTimelineEmbed
              key={theme.palette.type}
              sourceType="profile"
              // The preferred screen name goes next:
              screenName="MysterySignGame"
              theme={theme.palette.type}
              transparent
              // Style options goes here:
              options={{ height: 400 }}
            />
          </div>
          <Box
            className="Home-header"
            ref={howToPlayRef}
            color="secondary.main"
            fontSize="h4.fontSize"
            m={1}
          >
            How To play
          </Box>
          Mystery Sign is a online web multiplayer game.
          <br /> In Mystery Sign, you must find the answer to a problem to earn
          points.
          <br />
          The player with the most points after 5 rounds wins.
          <br />
          <p>
            <img width="100%" src={screenshot} alt="Screenshot"></img>
            The game consists of{" "}
            <Box component="span" color="secondary.main">
              5 rounds
            </Box>{" "}
            and a problem made up of two numbers and a mystery sign will be
            revealed to both players each round. You must then find the
            problem's hidden rule and guess the correct answer.
            <br />
          </p>
          There are two phases, the{" "}
          <LightBulbIcon style={{ color: "yellow" }} /> Hint Phase and a{" "}
          <QuestionIcon color="primary" /> guess phase. <br />
          <p>
            During the hint phase two random players will be assigned as the
            hinters.
            <br />
            The two hinters will give a number between -999 to 999 which will be
            used to form a hint.
            <br />
            The hint will give the answer based on the problem's hidden rule.
            <br />
          </p>
          <p>
            For example, the problem is{" "}
            <Box component="span" color="secondary.main">
              5?10=
            </Box>{" "}
            and the hidden rule is the{" "}
            <Box component="span" color="info.main">
              sum of the squares of the two numbers.
            </Box>
            <br />
            If the hinters give the numbers 3 and 2, the hidden rule will be
            applied and the hint given will be{" "}
            <Box component="span" color="info.main">
              3?2=13
            </Box>{" "}
            as 3<sup>2</sup> + 2<sup>2</sup> = 13. After seeing the hint, you
            must find the hidden rule based on the hint and solve{" "}
            <Box component="span" color="info.main">
              5<sup>2</sup> + 10<sup>2</sup>
            </Box>{" "}
            to get the answer{" "}
            <Box component="span" color="info.main">
              125.
            </Box>
            <br />
          </p>
          You will be given{" "}
          <Box component="span" color="success.main">
            1 minute
          </Box>{" "}
          to answer in the guess phase and can only attempt to submit an answer
          once in each guess phase.
          <br />
          The first player to figure out the pattern and submit a correct answer
          will earn{" "}
          <Box component="span" color="success.main">
            2 points.
          </Box>
          <br />
          The rest of the players that give the correct answer within the same
          guess phase will still receive{" "}
          <Box component="span" color="success.main">
            1 point.
          </Box>
          <br />
          If no one is able to guess within the <QuestionIcon color="primary" />{" "}
          guess phase, the game proceeds with a new{" "}
          <LightBulbIcon style={{ color: "yellow" }} /> hint phase.
          <br />
          If a player submitted a wrong answer while there are less than 3 hints
          shown, the player will be penalised and will get{" "}
          <Box component="span" color="error.main">
            -1 points.
          </Box>
          <br />
          The round ends if a single person is able to answer correctly.
          <br />
          <p>
            If no one is able to answer within three hints, the SKIP button will
            be available for players vote to skip the round.
            <br />
            If all players want to skip, the round will end after the next guess
            phase.
            <br />
            If there are at least one person who wants to skip, the round will
            end after 5 hints regardless of the number of people wanting to skip
            the round.
            <br />
          </p>
          <Box
            className="Home-header"
            ref={aboutRef}
            color="secondary.main"
            fontSize="h4.fontSize"
            m={1}
          >
            About
          </Box>
          <p>
            Mystery Sign is a free online multiplayer math puzzle game.
            <br />
            There is currently no limit on the number of players in a private
            room. Random public rooms is limited to 5 players.
            <br />
            Mystery Sign is made by me,Tan Boon Hing.
            <br />
            Thank you for checking out my game!
            <br />
            Be sure to check out my other game Unfair rage platformer!
          </p>
          <Box
            className="Home-header"
            ref={contactRef}
            color="secondary.main"
            fontSize="h4.fontSize"
            m={1}
          >
            Contact
          </Box>
          <p>
            <LinkedInIcon />
            Linkedin:{" "}
            <Box
              component="a"
              color="info.main"
              href="https://www.linkedin.com/in/boon-hing-tan/"
            >
              linkedin.com/in/boon-hing-tan
            </Box>
            <br />
            <EmailIcon />
            Email: 14pw.tanboonhing@gmail.com
          </p>
          <Box
            className="Home-header"
            ref={termsRef}
            color="secondary.main"
            fontSize="h4.fontSize"
            m={1}
          >
            Website Terms and Conditions of Use
          </Box>
          <h2>1. Terms</h2>
          <p>
            By accessing this Website, accessible from
            https://www.mysterysign.com, you are agreeing to be bound by these
            Website Terms and Conditions of Use and agree that you are
            responsible for the agreement with any applicable local laws. If you
            disagree with any of these terms, you are prohibited from accessing
            this site. The materials contained in this Website are protected by
            copyright and trade mark law.
          </p>
          <h2>2. Use License</h2>
          <p>
            Permission is granted to temporarily download one copy of the
            materials on mysterysign.com's Website for personal, non-commercial
            transitory viewing only. This is the grant of a license, not a
            transfer of title, and under this license you may not:
          </p>
          <ul>
            <li>modify or copy the materials;</li>
            <li>
              use the materials for any commercial purpose or for any public
              display;
            </li>
            <li>
              attempt to reverse engineer any software contained on
              mysterysign.com's Website;
            </li>
            <li>
              remove any copyright or other proprietary notations from the
              materials; or
            </li>
            <li>
              transferring the materials to another person or "mirror" the
              materials on any other server.
            </li>
          </ul>
          <p>
            This will let mysterysign.com to terminate upon violations of any of
            these restrictions. Upon termination, your viewing right will also
            be terminated and you should destroy any downloaded materials in
            your possession whether it is printed or electronic format. These
            Terms of Service has been created with the help of the{" "}
            <Box
              component="a"
              color="info.main"
              href="https://www.termsofservicegenerator.net"
            >
              Terms Of Service Generator
            </Box>
            .
          </p>
          <h2>3. Disclaimer</h2>
          <p>
            All the materials on mysterysign.com’s Website are provided "as is".
            mysterysign.com makes no warranties, may it be expressed or implied,
            therefore negates all other warranties. Furthermore, mysterysign.com
            does not make any representations concerning the accuracy or
            reliability of the use of the materials on its Website or otherwise
            relating to such materials or any sites linked to this Website.
          </p>
          <h2>4. Limitations</h2>
          <p>
            mysterysign.com or its suppliers will not be hold accountable for
            any damages that will arise with the use or inability to use the
            materials on mysterysign.com’s Website, even if mysterysign.com or
            an authorize representative of this Website has been notified,
            orally or written, of the possibility of such damage. Some
            jurisdiction does not allow limitations on implied warranties or
            limitations of liability for incidental damages, these limitations
            may not apply to you.
          </p>
          <h2>5. Revisions and Errata</h2>
          <p>
            The materials appearing on mysterysign.com’s Website may include
            technical, typographical, or photographic errors. mysterysign.com
            will not promise that any of the materials in this Website are
            accurate, complete, or current. mysterysign.com may change the
            materials contained on its Website at any time without notice.
            mysterysign.com does not make any commitment to update the
            materials.
          </p>
          <h2>6. Links</h2>
          <p>
            mysterysign.com has not reviewed all of the sites linked to its
            Website and is not responsible for the contents of any such linked
            site. The presence of any link does not imply endorsement by
            mysterysign.com of the site. The use of any linked website is at the
            user’s own risk.
          </p>
          <h2>7. Site Terms of Use Modifications</h2>
          <p>
            mysterysign.com may revise these Terms of Use for its Website at any
            time without prior notice. By using this Website, you are agreeing
            to be bound by the current version of these Terms and Conditions of
            Use.
          </p>
          <h2>8. Your Privacy</h2>
          <p>Please read our Privacy Policy.</p>
          <h2>9. Governing Law</h2>
          <p>
            Any claim related to mysterysign.com's Website shall be governed by
            the laws of sg without regards to its conflict of law provisions.
          </p>
          <Box
            className="Home-header"
            ref={privacyRef}
            color="secondary.main"
            fontSize="h4.fontSize"
            m={1}
          >
            Privacy Policy for mysterysign.com
          </Box>
          <p>
            At mysterysign.com, accessible from https://www.mysterysign.com, one
            of our main priorities is the privacy of our visitors. This Privacy
            Policy document contains types of information that is collected and
            recorded by mysterysign.com and how we use it.
          </p>
          <p>
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us.
          </p>
          <p>
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in mysterysign.com. This policy is
            not applicable to any information collected offline or via channels
            other than this website. Our Privacy Policy was created with the
            help of the{" "}
            <Box
              component="a"
              color="info.main"
              href="https://www.privacypolicygenerator.info/"
            >
              Privacy Policy Generator
            </Box>
            .
          </p>
          <h2>Consent</h2>
          <p>
            By using our website, you hereby consent to our Privacy Policy and
            agree to its terms.
          </p>
          <h2>Information we collect</h2>
          <p>
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information.
          </p>
          <p>
            If you contact us directly, we may receive additional information
            about you such as your name, email address, phone number, the
            contents of the message and/or attachments you may send us, and any
            other information you may choose to provide.
          </p>
          <p>
            When you register for an Account, we may ask for your contact
            information, including items such as name, company name, address,
            email address, and telephone number.
          </p>
          <h2>How we use your information</h2>
          <p>
            We use the information we collect in various ways, including to:
          </p>
          <ul>
            <li>Provide, operate, and maintain our website</li>
            <li>Improve, personalize, and expand our website</li>
            <li>Understand and analyze how you use our website</li>
            <li>Develop new products, services, features, and functionality</li>
            <li>
              Communicate with you, either directly or through one of our
              partners, including for customer service, to provide you with
              updates and other information relating to the website, and for
              marketing and promotional purposes
            </li>
            <li>Send you emails</li>
            <li>Find and prevent fraud</li>
          </ul>
          <h2>Log Files</h2>
          <p>
            mysterysign.com follows a standard procedure of using log files.
            These files log visitors when they visit websites. All hosting
            companies do this and a part of hosting services' analytics. The
            information collected by log files include internet protocol (IP)
            addresses, browser type, Internet Service Provider (ISP), date and
            time stamp, referring/exit pages, and possibly the number of clicks.
            These are not linked to any information that is personally
            identifiable. The purpose of the information is for analyzing
            trends, administering the site, tracking users' movement on the
            website, and gathering demographic information.
          </p>
          <h2>Google DoubleClick DART Cookie</h2>
          <p>
            Google is one of a third-party vendor on our site. It also uses
            cookies, known as DART cookies, to serve ads to our site visitors
            based upon their visit to www.website.com and other sites on the
            internet. However, visitors may choose to decline the use of DART
            cookies by visiting the Google ad and content network Privacy Policy
            at the following URL –{" "}
            <Box
              component="a"
              color="info.main"
              href="https://policies.google.com/technologies/ads"
            >
              https://policies.google.com/technologies/ads
            </Box>
          </p>
          <h2>Advertising Partners Privacy Policies</h2>
          <p>
            You may consult this list to find the Privacy Policy for each of the
            advertising partners of mysterysign.com.
          </p>
          <p>
            Third-party ad servers or ad networks uses technologies like
            cookies, JavaScript, or Web Beacons that are used in their
            respective advertisements and links that appear on mysterysign.com,
            which are sent directly to users' browser. They automatically
            receive your IP address when this occurs. These technologies are
            used to measure the effectiveness of their advertising campaigns
            and/or to personalize the advertising content that you see on
            websites that you visit.
          </p>
          <p>
            Note that mysterysign.com has no access to or control over these
            cookies that are used by third-party advertisers.
          </p>
          <h2>Third Party Privacy Policies</h2>
          <p>
            mysterysign.com's Privacy Policy does not apply to other advertisers
            or websites. Thus, we are advising you to consult the respective
            Privacy Policies of these third-party ad servers for more detailed
            information. It may include their practices and instructions about
            how to opt-out of certain options.{" "}
          </p>
          <p>
            You can choose to disable cookies through your individual browser
            options. To know more detailed information about cookie management
            with specific web browsers, it can be found at the browsers'
            respective websites.
          </p>
          <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>
          <p>
            Under the CCPA, among other rights, California consumers have the
            right to:
          </p>
          <p>
            Request that a business that collects a consumer's personal data
            disclose the categories and specific pieces of personal data that a
            business has collected about consumers.
          </p>
          <p>
            Request that a business delete any personal data about the consumer
            that a business has collected.
          </p>
          <p>
            Request that a business that sells a consumer's personal data, not
            sell the consumer's personal data.
          </p>
          <p>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </p>
          <h2>GDPR Data Protection Rights</h2>
          <p>
            We would like to make sure you are fully aware of all of your data
            protection rights. Every user is entitled to the following:
          </p>
          <p>
            The right to access – You have the right to request copies of your
            personal data. We may charge you a small fee for this service.
          </p>
          <p>
            The right to rectification – You have the right to request that we
            correct any information you believe is inaccurate. You also have the
            right to request that we complete the information you believe is
            incomplete.
          </p>
          <p>
            The right to erasure – You have the right to request that we erase
            your personal data, under certain conditions.
          </p>
          <p>
            The right to restrict processing – You have the right to request
            that we restrict the processing of your personal data, under certain
            conditions.
          </p>
          <p>
            The right to object to processing – You have the right to object to
            our processing of your personal data, under certain conditions.
          </p>
          <p>
            The right to data portability – You have the right to request that
            we transfer the data that we have collected to another organization,
            or directly to you, under certain conditions.
          </p>
          <p>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </p>
          <h2>Children's Information</h2>
          <p>
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
          </p>
          <p>
            mysterysign.com does not knowingly collect any Personal Identifiable
            Information from children under the age of 13. If you think that
            your child provided this kind of information on our website, we
            strongly encourage you to contact us immediately and we will do our
            best efforts to promptly remove such information from our records.
          </p>
        </Typography>
      </Grid>
    </Container>
  );
}

export default Home;
